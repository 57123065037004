import "./styles.scss"

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper"

import Button from "components/Button"
import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const DiagnosisContent = ({ diagnosis }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination])

  const { gallery, mainImg, description } = diagnosis

  const diagnosisSliderSettings = {
    loop: false,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 3,
    centeredSlides: false,
    speed: 700,
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    pagination:
      gallery && gallery?.length > 3
        ? {
            clickable: true,
            bulletClass: `single-diagnosis__slider-bullets`,
          }
        : false,
    navigation:
      gallery && gallery?.length > 3
        ? {
            nextEl: ".single-diagnosis__slider-arrow--next",
            prevEl: ".single-diagnosis__slider-arrow--prev",
          }
        : false,
  }

  return (
    <section className="single-diagnosis">
      <div className="container">
        <div className="single-diagnosis__wrapper">
          <div className="single-diagnosis__image">
            <img src={mainImg?.sourceUrl} alt={mainImg?.altText} />
          </div>
          <div className="single-diagnosis__main">
            <div
              className="single-diagnosis__content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div
          className={`single-diagnosis__slider${
            gallery && gallery?.length > 3 ? " active" : ""
          }`}
        >
          <Swiper {...diagnosisSliderSettings}>
            {gallery?.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="single-diagnosis__slider-image">
                    <img src={image?.sourceUrl} alt={image?.altText} />
                  </div>
                </SwiperSlide>
              )
            })}
            {gallery && gallery.length > 3 ? (
              <div className="single-diagnosis__nav">
                <div className="single-diagnosis__slider-arrows">
                  <div className="single-diagnosis__slider-arrow single-diagnosis__slider-arrow--prev">
                    <SliderArrow />
                  </div>
                  <div className="single-diagnosis__slider-arrow single-diagnosis__slider-arrow--next">
                    <SliderArrow />
                  </div>
                </div>
              </div>
            ) : null}
          </Swiper>
        </div>
        <div className="single-diagnosis__cta">
          <p>Potrzebujesz pomocy podologa?</p>
          <Button type="button" className="button-dark-green" to="/kontakt/">
            Skontaktuj się z nami
          </Button>
        </div>
      </div>
    </section>
  )
}

export default DiagnosisContent
