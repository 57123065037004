import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import DiagnosisContent from "page_components/single-diagnosis/DiagnosisContent"

const SingleDiagnosis = ({ pageContext }) => {
  const offer_type = pageContext?.offer

  const breadcrumbs_data = [
    {
      name: offer_type === "Zabiegi" ? "Zabiegi" : "Diagnostyka",
      href: offer_type === "Zabiegi" ? "/zabiegi/" : "/diagnostyka/",
    },
    {
      name: pageContext.data.title,
      href: pageContext?.data?.uri,
    },
  ]

  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header={pageContext?.data?.title} />
      <DiagnosisContent
        diagnosis={
          offer_type === "Zabiegi"
            ? pageContext?.data?.acfTreatments
            : pageContext?.data?.acfDiagnostics
        }
      />
    </Layout>
  )
}

export default SingleDiagnosis
